<template>
  <Dialog :is-loading="isLoading">
    <div class="flex flex-col p-md">
      <div v-if="!isEdit">
        <h3 class="text-lg font-bold leading-2xs">
          {{
            t('account.payment.paymentMethods.debitDialog.new.instructionsOne')
          }}
        </h3>
        <p class="mt-sm mb-md">
          {{
            t('account.payment.paymentMethods.debitDialog.new.instructionsTwo')
          }}
        </p>
      </div>
      <NotificationBar :type="NotificationType.WARN" class="!mt-0">
        <div v-if="!isEdit" class="py-xs">
          <Trans keypath="account.payment.paymentMethods.debitDialog.new.hint">
            <a
              class="font-bold cursor-pointer text-primary-base"
              @click="redirectTo('/account/orders#invoices')"
            >
              {{ t('shop.yourBills') }}
            </a>
          </Trans>
        </div>
        <div v-else class="py-xs">
          <Trans keypath="account.payment.paymentMethods.debitDialog.edit.hint">
            <a
              class="font-bold cursor-pointer text-primary-base"
              @click="redirectTo('/kontakt?topic=customerdata')"
            >
              {{ t('contactform') }}
            </a>
          </Trans>
        </div>
      </NotificationBar>
      <BankDataInput
        class="mt-md"
        :class="{ '!mt-0': bankData.error.includes('BANKDATA_INVALID') }"
        :model-value="bankData"
        :hide-collective-invoice-select="true"
        autofocus
        @update:model-value="(v) => updateBankData(v)"
      ></BankDataInput>
      <BasicButton
        full-width
        class="!w-full mt-md"
        :label="t('account.payment.paymentMethods.debitDialog.giveSEPAaccess')"
        :show-dialog="error"
        :dialog-content="{
          dialogHeadline: t(
            'account.payment.paymentMethods.debitDialog.error.headline',
          ),
          dialogText: t(
            'account.payment.paymentMethods.debitDialog.error.text',
          ),
          dialogType: 'error',
        }"
        @click="handleSaveClick"
        @close-dialog="() => (error = false)"
      />
      <div
        class="mx-auto cursor-pointer text-primary-base px-sm mt-sm"
        @click="changeBankDataDialog.close()"
      >
        {{ t('formFields.form.cancel') }}
      </div>
    </div>
  </Dialog>
</template>
<script setup lang="ts">
import Dialog from '@/components/dialog/components/dialogFrame/local/dialogFrame.vue';
import useChangeBankDataDialog from '@/composables/dialogs/useChangeBankDataDialog';
import BankDataInput from '@/components/shop/checkout/payment/bankDataInput.vue';
import { NotificationBar, NotificationType, BasicButton } from '@/complib';
import useSubmitBankData from '~/composables/user/useSubmitBankData';
import type { BankData } from '@/components/shop/checkout/payment/types';
import { useUserContext } from '~/stores/useUserContext';
import { PaymentMethodId } from '@/@types/paymentMethodIds';
import Trans from '@/components/Trans.vue';

const { t } = useTrans();
const changeBankDataDialog = useChangeBankDataDialog();
const isEdit = changeBankDataDialog.getData().isEdit;
const error = ref<boolean>(false);
const isLoading = ref(false);

const bankData = ref<BankData>({
  bankName: '',
  iban: '',
  bic: '',
  error: ['BANKDATA_INCOMPLETE'],
});

async function updateBankData(val: BankData) {
  bankData.value = val;
}
async function handleSaveClick() {
  if (bankData.value.error.length > 0) {
    bankData.value.error = ['BANKDATA_INVALID'];
    return;
  }

  isLoading.value = true;
  try {
    if (!isEdit) {
      await saveBankeData();
      await changePaymentMethodToDebit();
    } else {
      await saveBankeData();
    }
  } catch {
    isLoading.value = false;
    error.value = true;
    return;
  }

  isLoading.value = false;
  useUserContext().loadAccountData(true);
  changeBankDataDialog.close();
}
async function saveBankeData() {
  await useSubmitBankData(bankData.value);
}

async function changePaymentMethodToDebit() {
  await useUserContext().changeUserContext({
    paymentMethodId: PaymentMethodId.DEBIT,
  });
}

function redirectTo(path: string) {
  window.open(path, '_blank');
}
</script>
<style scoped lang="postcss">
:deep(.showMoreLessArrow) {
  @apply text-primary-base font-bold text-lg;
}
</style>
